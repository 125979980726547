import './ProductSearch.scss';

import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, FormControl, InputLabel, Select, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { useSearchForProductsLazyQuery } from '../../../../../../../generated/graphql';
import LoaderCircular from '../../../../../../Common/Common/LoaderCircular';
import { useStore } from '../../../../../../Common/context/store-context';
import useElementMouseClick from '../../../../../../Common/custom-hooks/useElementMouseClick';
import AppSearchDebounce from '../../../../../../Common/Form/AppSearchDebounce';
import { priceFormat } from '../../../../../utils/productHelper';

export function ProductSearch({ onProductSelection, orderId }) {
    const [storeId] = useStore();

    let [search, { data, loading }] = useSearchForProductsLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    const searchRef = useRef({});
    const ref = useRef(null);

    const products = data?.searchForProducts?.items;

    const [showResults, setShowResults] = useState(false);
    const [currentSearch, setCurrentSearch] = useState('');
    const [currentSearchType, setCurrentSearchType] = useState('ean');

    useEffect(() => {
        if (currentSearch && currentSearch !== '-' && products && products.length) {
            const matches = products.filter((product) => product.EanCode === currentSearch)

            // If there is only one match on ean code, select it.
            if (matches.length === 1) {
                onProductSelection(matches[0]);
                setCurrentSearch('');
                searchRef.current.reset();
                searchRef.current.focus();
            }
        }
    }, [products, currentSearch, onProductSelection]);

    useElementMouseClick(ref, (isInside) => {
        setShowResults(isInside);
    })

    const onSearch = async (query, type) => {
        if (query && query.length > 2) {
            setCurrentSearch(query);

            await search({ variables: {
                type: type ?? currentSearchType,
                query,
                page: 1,
                storeId,
            } });

            return;
        }

        // Don't show results. No search query has been executed.
        setCurrentSearch('');
    };

    const placeholder = ({
        ean: 'Scanna eller ange streckkod',
        sku: 'Ange artikelnummer',
        name: 'Ange valfri sökterm, t.ex. produktnamn',
    })[currentSearchType];

    const labelId = `product-search-label-${orderId}`;

    return (
        <Stack direction="row" sx={{ gap: 4 }}>
            <FormControl sx={{ width: '210px' }} fullWidth>
                <InputLabel
                    id={labelId}
                    shrink
                    sx={{
                        zIndex: 0,
                    }}
                >Typ av sökning</InputLabel>
                <Select
                    variant="outlined"
                    labelId={labelId}
                    value={currentSearchType}
                    input={
                        <OutlinedInput
                            notched
                            label="Typ av sökning"
                        />
                    }
                    onChange={async ({ target }) => {
                        setCurrentSearchType(target.value);

                        if (currentSearch.length > 2) {
                            setShowResults(true);
                        }

                        await onSearch(currentSearch, target.value);
                    }}
                >
                    <MenuItem value="ean">Streckkod</MenuItem>
                    <MenuItem value="sku">Artikelnummer</MenuItem>
                    <MenuItem value="name">Fritext</MenuItem>
                </Select>
            </FormControl>
            <Box ref={ref} sx={{ flexGrow: 1 }}>
                <AppSearchDebounce
                    label='Lägg till artikel'
                    placeholder={placeholder}
                    onSearch={onSearch}
                    searchRef={searchRef}
                />
                {showResults && currentSearch.length > 2 && (
                    <>
                        <ProductResult
                            onProductSelection={(v) => {
                                onProductSelection(v);
                                setCurrentSearch('');
                                searchRef.current.reset();
                                searchRef.current.focus();
                            }}
                            result={products || []}
                            loading={loading}
                            currentSearch={currentSearch}
                        />
                    </>
                )}
            </Box>
        </Stack>
    );
}

function ProductResult({ result = [], onProductSelection, loading, currentSearch }) {
    // Don't show list if no products.
    if (!result && !loading) {
        return null;
    }

    const sortedResult = [...result].sort((a, b) => {
        const OnHandValueA = a?.OnHands?.[0]?.OnHandValue ?? -1; // Default -1 ensures out-of-stock items are last
        const OnHandValueB = b?.OnHands?.[0]?.OnHandValue ?? -1;

        return OnHandValueB - OnHandValueA; // Sort descending
    });

    return (
        <div className='product-result-parent'>
            <div className='product-result card'>
                <LoaderCircular visible={loading} size={50} />
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>ArtNr</TableCell>
                                <TableCell>EAN</TableCell>
                                <TableCell>Beskrivning</TableCell>
                                <TableCell>Lager</TableCell>
                                <TableCell align='right'>Pris</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedResult?.map((product, i) => (
                                <ProductMatch
                                    onSelect={onProductSelection}
                                    key={i}
                                    product={product}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {currentSearch && !result.length && !loading && (
                    <div className='product-result-no-matches'>Inga produkter hittades</div>
                )}
            </div>
        </div>
    );
}

function ProductMatch({ onSelect, product, rowIndex }) {
    return (
        <TableRow
            className='product-result-match'
            key={rowIndex}
            onClick={() => onSelect(product)}
        >
            <TableCell>{product.PartNo}</TableCell>
            <TableCell>{product.EanCode}</TableCell>
            <TableCell>{product.Name}</TableCell>
            <StockQty product={product} container={<TableCell />}></StockQty>
            <TableCell align='right'>
                <span>{priceFormat(product.PriceIncVat)}</span>
            </TableCell>
        </TableRow>
    );
}

function StockQty({ product, container }) {
    const [item] = product?.OnHands ?? [];

    return React.cloneElement(container, {
        children: item?.OnHandValue ?? '-'
    });
}
