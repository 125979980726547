import React from 'react';

import { Customer, useSearchForCustomersLazyQuery } from '../../../../../../generated/graphql';
import { useDialog3 } from '../../../../../Common/custom-hooks/useDialog3';
import EditCustomerDialog from '../../../../../Customer/CustomerDialogs/EditCustomerDialog';
import { QuickSearch } from './QuickSearch';

const columns = [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
        grow: 2,
    },
    {
        name: 'Kundens namn',
        selector: 'name',
        cell: (row: Customer) => row.name || '-',
        sortable: false,
        grow: 3,
    },
    {
        name: 'Personnummer',
        selector: 'socialSecurityNumber',
        cell: (row: Customer) => row.socialSecurityNumber || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'E-post',
        selector: 'email',
        cell: (row: Customer) => row.email || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Tel.',
        selector: 'cellPhoneNumber',
        cell: (row: Customer) => row.cellPhoneNumber || row.phoneNumber || '-',
        width: '140px',
        sortable: true,
        grow: 3,
    },
];

export function CustomerSearchPanel() {
    const { createDialog } = useDialog3();

    const [
        getCustomers,
        {
            data,
            loading,
            error,
            called,
        }
    ] = useSearchForCustomersLazyQuery({
        fetchPolicy: 'network-only',
    });

    const onSearch = async (query: string) => {
        if (query && query.length > 0) {
            await getCustomers({ variables: { query } });
        }
    };

    const onSelect = (customerId: number) => {
        createDialog((ref) => (
            <EditCustomerDialog customerId={customerId} dialogRef={ref} />
        ));
    };

    return (
        <QuickSearch
            error={error}
            id='customer-search'
            label='Sök'
            placeholder='Personnummer, telefonnummer eller kundens namn'
            onSearch={onSearch}
            loading={loading}
            columns={columns}
            items={data?.searchForCustomers?.items}
            onSelect={onSelect}
            called={called}
            noResultText={'Inga kunder hittades'}
        />
    );
}
