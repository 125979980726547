import './Products.scss';

import Grid from '@material-ui/core/Grid';
import { Box } from '@mui/material';
import React, { useState } from 'react';

import { useAddServiceOrderRowMutation } from '../../../../../../generated/graphql';
import LoaderCircularDiscrete from '../../../../../Common/Common/LoaderCircularDiscrete';
import ProductsCategorySelector from './ProductCategorySelector/ProductCategorySelector';
import { ProductSearch } from './ProductSearch/ProductSearch';
import { FavouriteProductSelector } from './ProductSelector/FavouriteProductsSelector';
import ProductSelector from './ProductSelector/ProductSelector';
import SelectedProductsTable from './SelectedProducts/SelectedProductsTable';

export default function ProductsTab({ serviceOrder }) {
    const [categoryId, setCategoryId] = useState(null);
    const [addProductMutation, { loading: addProductLoading, error }] = useAddServiceOrderRowMutation();
    const finished = serviceOrder?.status === 'closed';

    const addProduct = (sku) => {
        if (finished) {
            return;
        }
        addProductMutation({
            variables: {
                productSku: sku,
                serviceOrderId: serviceOrder.id,
            },
        }).catch(() => {
        });
    };

    const handleProductSelection = (product) => {
        addProduct(product.PartNo);
    };

    if (finished) {
        return (
            <div className="service-order-products">
                <SelectedProductsTable serviceOrder={serviceOrder} locked={true} />
            </div>
        );
    }

    return (
        <div className="service-order-products">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ProductSearch onProductSelection={handleProductSelection} orderId={serviceOrder.id} />
                    <div>
                        {error?.message === 'GraphQL error: Product not found!' && (
                            <span>Produkten hittades inte</span>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <SelectedProductsTable serviceOrder={serviceOrder} />
                </Grid>
                <Grid item xs={3} className="loader-container">
                    <ProductsCategorySelector
                        onChange={(value) => setCategoryId(value)}
                    />
                </Grid>
                <Grid item xs={9} className="loader-container">
                    <Box
                        sx={{ marginBottom: 3 }}
                    >
                        <h4>Favoriter</h4>
                        <FavouriteProductSelector
                            categoryId={categoryId}
                            onSelect={(p) => addProduct(p.PartNo)}
                        />
                    </Box>
                    {categoryId && (
                        <Box
                            key={categoryId} // Reload on change
                            gap={1}
                        >
                            <h4>Produkter i kategori</h4>
                            <LoaderCircularDiscrete
                                visible={addProductLoading}
                            />
                            <ProductSelector
                                categoryId={categoryId}
                                onSelect={(p) => addProduct(p.PartNo)}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
