import styled from '@emotion/styled';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { css } from 'emotion';
import React from 'react';
import DataTable from 'react-data-table-component';

import {
    useSearchForCompanyCustomersLazyQuery,
} from '../../../generated/graphql';
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { useDialog3 } from '../../Common/custom-hooks/useDialog3';
import AppSearchDebounce from '../../Common/Form/AppSearchDebounce';
import { colors } from '../../Common/styles/colors';
import CreateCustomerDialog from '../../Customer/CustomerDialogs/CreateCustomerDialog';
import { NoResult } from '../../Layout/QuickSearch/components/Dialog/Panels/QuickSearch';

const TableWrapper = styled.div`
    position: relative;
    margin: 30px 0 15px;
`;

const Container = styled.div`
    box-sizing: border-box;
    margin-top: 30px;
    padding: 0 24px;
    width: 100%;
`;

const customerTableStyles = css`
    border: 2px solid ${colors.grayScale[1]};
    border-radius: 5px;
    overflow-x: auto;
    min-width: 100%;
`;

const SearchCompanyCustomer = ({ onSelectCustomer }) => {
    const [
        searchCompanyCustomer,
        {
            data,
            loading,
            called,
        }
    ] = useSearchForCompanyCustomersLazyQuery({
        fetchPolicy: 'network-only',
    });

    const { createDialog } = useDialog3();

    const openCustomerCreateDialog = () => {
        const ref = createDialog((ref) => (
            <CreateCustomerDialog dialogRef={ref} />
        ));
        ref.onClose.push(onSelectCustomer);
    };

    const columns = [
        {
            name: 'Kundnummer',
            selector: 'id',
            grow: 0,
            sortable: true,
        },
        {
            name: 'Företagsnamn',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Org.nummer',
            selector: 'orgNumber',
            sortable: true,
        },
        {
            name: 'Telefonnummer',
            selector: 'phoneNumber',
            sortable: true,
        },
        {
            name: 'E-post',
            selector: 'email',
            sortable: true,
        },
        {
            button: true,
            cell: (customer) => (
                <Button
                    onClick={() => onSelectCustomer({ id: customer.customerId })}
                    variant='contained'
                    color='primary'
                >
                    Välj
                </Button>
            ),
        },
    ];

    const items = data?.searchForCompanyCustomers.items || [];

    return (
        <Container>
            <AppSearchDebounce
                id='searchCompanyId'
                label='Sökord'
                onSearch={(value) => value && value.length > 1 && searchCompanyCustomer({ variables: { query: value } })}
                delay={600}
            />
            <TableWrapper>
                <LoaderCircular visible={loading} size={50} />
                {called && (
                    <div className={customerTableStyles}>
                        <DataTable
                            noHeader
                            columns={columns}
                            defaultSortField="id"
                            data={items}
                            striped
                            noDataComponent={(<NoResult>Hittade inga kunder</NoResult>)}
                        />
                    </div>
                )}
            </TableWrapper>
            <div className="button-wrapper-right">
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                    onClick={openCustomerCreateDialog}
                >
                    Lägg till ny kund
                </Button>
            </div>
        </Container>
    );
};

export default SearchCompanyCustomer;
