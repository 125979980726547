import { Customer } from "src/generated/graphql";

type getCompanyNameFromCustomerInputType = {
    companyName?: string;
    company?: {
        companyName?: string;
    }
};

export function isEmail(email: string): string | true {
    if(!email) {
        return true;
    }
    const re = /\S+@\S+\.\S+/;
    if(re.test(email)){
        return true;
    }
    return "Ogiltig e-postadress";
}

export function isCompanyCustomer(customer: Partial<Customer>): boolean {
    return Boolean(customer?.company);
}

export function getCompanyNameFromCustomer(customer: getCompanyNameFromCustomerInputType): string {
    return customer?.companyName || customer?.company?.companyName || '';
}

export function validateOrgNum(orgNumStr: string): string | true {
    const validNumber = /^\d{6}-\d{4}$/;

    if (!validNumber.test(orgNumStr)) {
        return 'Måste innehålla 8 siffror följt av ett bindestreck följt av 4 siffror.';
    }

    const digits = orgNumStr.replace('-', '');

    if (!validateLuhn(digits)) {
        return 'Ogiltig kontrollsiffra, ange ett korrekt organisationsnummer.';
    }

    return true;
}

function validateLuhn(number: string): boolean {
    const digits = number.split('').reverse().map(Number);
    const sum = digits.reduce((acc, digit, idx) => {
        if (idx % 2 === 0) {
            return acc + digit;
        } else {
            const doubled = digit * 2;
            return acc + (doubled > 9 ? doubled - 9 : doubled);
        }
    }, 0);
  
    return sum % 10 === 0;
}

export function convertCustomerToCompanyInput(customer: Customer) {
    const company = customer?.company;
    if (!company) {
        return undefined;
    }

    return {
        ...company,
        __typename: undefined,
        billingAddress: {
            ...company.billingAddress,
            __typename: undefined,
        },
        shippingAddress: company.shippingAddress?.map((address: any) => ({
            ...address,
            __typename: undefined,
        })),
        visitingAddress: company.visitingAddress ? {
            ...company.visitingAddress,
            __typename: undefined,
        } : undefined,
        contacts: [
            {
                id: customer.id,
                email: customer.email,
                name: customer.name,
                phoneNumber: customer.cellPhoneNumber,
                // The Storm API magically returns the cellPhoneNumber in the phoneNumber field.
                // This is a bug.
                // Not sure if it's connected to saving Companies or if it does that in general for Customers.
            },
        ]
    };
}
